import { Link, Typography, TypographyProps } from '@mui/material'
import parse, {
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
} from 'html-react-parser'

const parseOptions: HTMLReactParserOptions = {
  replace(domNode) {
    const { name, children, attribs } = domNode as DOMNode & {
      name: string
      children: any
      attribs: any
    }
    if (name === 'p')
      return (
        <Typography variant="body1">
          {domToReact(children, parseOptions)}
        </Typography>
      )
    else if (name === 'a')
      return (
        <Link href={attribs.href}>{domToReact(children, parseOptions)}</Link>
      )
    else if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name))
      return (
        <Typography variant={name as TypographyProps['variant']}>
          {domToReact(children, parseOptions)}
        </Typography>
      )
  },
}

const HtmlToMui: React.FC<{ html: string }> = ({ html }) => {
  return <div>{parse(html || '', parseOptions)}</div>
}

export default HtmlToMui
