import styled from '@emotion/styled'
import { Box, Fade, Theme, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import HtmlToMui from 'components/organisms/HtmlToMui'
import { HEADER_HEIGHT, MAP_CENTER, MAP_WIDE_ZOOM, SIDEBAR_WIDTH } from 'lib'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useMap } from 'react-map-gl'

const Root = styled.div`
  background: white;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`

const Content = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  z-index: 1200;
  top: ${HEADER_HEIGHT}px;
  left: 0;
`

const Desktop = styled(Box)<{
  bg: string
}>`
  position: absolute;
  width: ${`${SIDEBAR_WIDTH}px`};
  z-index: 100;
  top: 100px;
  padding: 0 24px;
  background-color: ${(props) => props.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
`

const Mobile = styled(Box)<{
  theme: Theme
}>`
  position: absolute;
  top: 15px;
  width: 100%;
  padding: 0 15px;
  z-index: 100;
`

export default function Home({ content }: { content: string }) {
  const theme = useTheme()
  const { default: map } = useMap()
  const router = useRouter()
  const { embed, brand } = useContext(StrikeContext)
  const desktop = useMediaQuery(theme.breakpoints.up('md')) && !embed

  useEffect(() => {
    if (map) {
      map.setZoom(MAP_WIDE_ZOOM)
      map.setCenter(MAP_CENTER)
    }
  }, [router.asPath, map])

  return (
    <Root>
      <Fade in>
        <Content>
          {desktop ? (
            <Desktop
              bg={brand?.colours?.strikeCard || theme.palette.primary.main}
            >
              <Box sx={{ width: '100%' }}>
                <HtmlToMui html={content} />
              </Box>
            </Desktop>
          ) : (
            <Mobile theme={theme} />
          )}
        </Content>
      </Fade>
    </Root>
  )
}
