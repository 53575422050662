import Home from 'components/templates/Home'
import Layout from 'components/templates/Layout'
import prisma from 'lib/server/prisma'

export default function Main({ content }: { content: string }) {
  return <Home content={content} />
}

Main.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>
}

export async function getStaticProps() {
  const content = await prisma.config.findUnique({
    where: { key: 'pageContent_home' as string },
  })

  return {
    props: {
      content: content?.value || '',
    },
  }
}
